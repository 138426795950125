import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["backLink"]

  backToDashboard() {
    loadPageFor(this.backLinkTarget);
    // PWA Apple Status bar
    $('body').removeClass('bg-blue-ultra-light').addClass('bg-color-customed');
  }

  backToActionSwiper() {
    loadPageFor(this.backLinkTarget);
  }
}

function loadPageFor(target) {
  moveArrow();
  loadContentFor(target);
}

function moveArrow() {
  $('.my-company-pages-back i').width("35px");
}

function loadContentFor(target) {
  let url = target.dataset.url;
  let callback = defineCallback(target);
  $.ajax({
    url: url,
    type: 'GET',
    dataType: 'json',
    data: { 'origin': 'navigation' },
    success: (data) => {
      $('#app-content').html(data['content']);
      if (data['navbar']) {
        $('body').append(data['navbar'])
        navbarAnimation();
      }
      if (typeof callback == "function") { callback(); }
    }
  });
}

function defineCallback(target) {
  let callbackString = target.dataset.callback
  if (callbackString) { return window[callbackString] }
}
