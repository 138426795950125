import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { adaptFiltersForTabulator, updateTracksCount, activeElements } from "packs/controllers/desktop/hr/filters/utils";

window.Tabulator = Tabulator;

export function loadTabulator(tracks, filters) {
  const tabulator = new Tabulator("#tracks", {
    minHeight: 400,
    responsiveLayout: "collapse",
    pagination: true,
    paginationSize: 20,
    paginationButtonCount: 1,
    movableColumns: false,
    placeholder: renderBlankState(),
    data: tracks,
    layout: "fitColumns",
    paginationElement: document.getElementById("tracks-pagination"),
    langs: setLanguage(),
    columns: [
      { field: "organization_ids", visible: false }, // for sorting
      { field: "mobility", visible: false }, // for sorting
      { field: "status", visible: false, sorter:function(a, b) {
        return statusOrder()[a] - statusOrder()[b]
      }},
      { field: "collaborator_name", minWidth: 380, vertAlign: 'middle', hozAlign: "left", headerHozAlign: "center", resizable: false, headerClick:sort },
      { title: '', field: 'bulk', width: 10, vertAlign: 'middle', hozAlign: 'left', headerHozAlign: 'left', resizable: false },
      { field: "organization_name", minwidth: 200, vertAlign: 'middle', hozAlign: "left", headerHozAlign: "left", resizable: false, headerClick:sort, visible: organizationNameVisible() },
      { field: "status_text", width: 200, vertAlign: 'middle', hozAlign: "left", headerHozAlign: "left", resizable: false, headerClick:sort, sorter:function(_a, _b, aRow, bRow, _column, _dir, _sorterParams) {
        const aStatus = aRow.getData().status;
        const bStatus = bRow.getData().status;
        return statusOrder()[aStatus] - statusOrder()[bStatus]
      } },
      {
        field: "date", width: 120, vertAlign: 'middle', hozAlign: "left", headerHozAlign: "center", resizable: false, headerClick:sort, sorter:function(a, b){
        return new Date(a) - new Date(b)
      }},
      { field: "progress", width: 120, responsive: 1, vertAlign: 'middle', hozAlign: "left", headerHozAlign: "center", resizable: false, headerClick: sort },
    ],
    rowFormatter:function(row) {
      var data = row.getData();
      var rowEl = row.getElement();
      rowEl.classList.add("margin-vertical-10px", "bg-white", "medium-border-radius", "d-flex", "pointer");
      rowEl.dataset.controller = 'desktop--hr--tracks tracking';
      rowEl.dataset.action = "click->desktop--hr--tracks#select click->tracking#track";
      rowEl.dataset.mobility = data.mobility;
      rowEl.dataset.trackId = data.id;
      rowEl.dataset.employeeId = data.employee_id;
      rowEl.dataset.status = data.status;
      rowEl.dataset.featFeedback = data.feat_feedback;
      rowEl.dataset.trackedEvent = "Get to boardee profil page";
      rowEl.dataset.trackedMobility = data.mobility;
      rowEl.dataset.trackedEmployee_id = data.employee_id;
      rowEl.dataset.trackedStatus = data.status;
      rowEl.dataset.trackedSearch = filters.query;
      rowEl.dataset.trackedSub_smartfilter = filters.smart_filter;
      rowEl.dataset.trackedImplication_scope = filters.implication_scope;
      rowEl.dataset.trackedCurrent_sign_in_at = document.getElementById('collaborators-container').dataset.trackedCurrent_sign_in_at;
      renderHtmlFor(rowEl, data);
    }
  });
  tabulator.setFilter(adaptFiltersForTabulator(filters.column_filters));
  const locale = document.getElementById('locale').dataset.locale;
  const sorting = setSorting();
  setTimeout(() => {
    tabulator.setLocale(`${locale}-${locale}`);
    tabulator.setSort(sorting);
    // if only one sort rule, it means the user sorted himself, so we display arrow
    if (sorting.length == 1) { showArrow(sorting[0].column.element) }
    updateTracksCount();
  }, 100);
  updateBlankState();
}

function renderHtmlFor(row, data) {
  // the following object is used to render the html for the row
  // the key is the id of the template tag AND the anchor to replace the html of the tabulator row
  // the values are the attributes to be replaced in the html, matching the properties of the data we receive
  const matchingObj = {
    collaborator_name: ["image_url", "collaborator_name", "employee_id", "mobility", "mobility_text", "job_position", "collaborator_mobility"],
    status_text: ["status", "status_text"],
    organization_name: ["organization_name"],
    date: ["date_text"],
    progress: ["progress"]
  };

  Object.keys(matchingObj).forEach(template_id => {
    const templateHtml = document.getElementById(template_id).innerHTML;
    let finalHtml = templateHtml;
    matchingObj[template_id].forEach(attribute => {
      finalHtml = finalHtml.replace(`{{${attribute}}}`, escapeHtml(data[attribute]));
    });
    row.querySelector(`[tabulator-field="${template_id}"]`).innerHTML = finalHtml;
  });
}

function setLanguage() {
  const data = document.getElementById('locale');
  const locale = data.dataset.locale;
  let localeObj = {};
  localeObj[`${locale}-${locale}`] = {
    "columns": {
      "collaborator_name": data.dataset.collaboratorColumn,
      "organization_name": data.dataset.organizationColumn,
      "status_text": data.dataset.statusColumn,
      "date": data.dataset.dateColumn,
      "progress": data.dataset.progressColumn
    },
    "pagination": {
      "first": data.dataset.firstPage,
      "last": data.dataset.lastPage,
      "prev": data.dataset.prevPage,
      "next": data.dataset.nextPage
    }
  }
  return localeObj;
}


// This function is executed only when the table is loaded, not when we change the column filters
function renderBlankState() {
  return blankState(activeElements());
}

// This function update the blank state when we click on the column filters
// as we don't reload the table to calculate the new placeholder
function updateBlankState() {
  document.getElementById('selects').addEventListener('click', _e => {
    const blankStateContainer = document.querySelector('.tabulator-placeholder-contents');
    if (!blankStateContainer) { return }

    const newBlankState = blankState(activeElements());
    Tabulator.findTable('#tracks')[0].options.placeholder = newBlankState;
    blankStateContainer.innerHTML  = newBlankState;
  });
}

// depending on the scope, filters and search, this function returns the proper blank state in html
// this function calculs a code string of 3 characters, 0 or 1
// the first number is the scope
// the second number is the search
// the third number is the filters
// 1 : the element is activated
// 0 : the element is not activated
// Ex : 101 : scope actived, no value in search, a filter is activated
// Ex : 011 : scope not actived, value in search, a filter is activated
// Ex : 100 : scope actived, no search, no filters are activated
function blankState(activatedElements) {
  let code = [activatedElements.scope,
              activatedElements.search,
              (activatedElements.smartFilters || activatedElements.columnFilters)]
              .map(el => el ? '1' : '0')
              .join('');
  return document.querySelector(`[data-code="${code}"]`).innerHTML;
}

function sort(e, columnFilter) {
  showArrow(e.target.parentElement);
  const filters = JSON.parse(document.getElementById('filters').dataset.filters);
  const element = columnFilter.getElement();
  const column = element.getAttribute('tabulator-field');
  const direction = element.getAttribute('aria-sort');
  const newSorting = [{column: column, dir: direction}];
  filters.sorting = newSorting;
  document.getElementById('filters').dataset.filters = JSON.stringify(filters);
}

function showArrow(element) {
  document.querySelectorAll('.tabulator-arrow').forEach(arrow => arrow.style.display = 'none');
  element.querySelector('.tabulator-arrow').style.display = 'block';
}

function statusOrder() {
  return {
    building: 1,
    pending_invitation: 2,
    upcoming_invitation: 3,
    in_progress: 4,
    cancelled: 5,
    closed: 6
  }
}

// By default we sort the table by status then by date
// if the user clicked a smart filter, we sort by status and date
// but if we get the filters object with only one value for sorting, it means the user sorted himself by clicking
// therefore we don't want to overwrite his sorting and keep it
// the only way to get back to the original sorting (status, date) is to reload the page, as we persist the sorting
function setSorting() {
  const filters = JSON.parse(document.getElementById('filters').dataset.filters);
  if (filters.sorting.length > 1 && filters.smart_filter !== '') {
    document.getElementById('filters').dataset.filters = JSON.stringify(filters);
  }
  return filters.sorting;
}

function escapeHtml(text) {
  if (typeof(text) !== 'string') { return text };

  return text.replace(/&/g, "&amp;")
             .replace(/</g, "&lt;")
             .replace(/>/g, "&gt;")
             .replace(/"/g, "&quot;")
             .replace(/'/g, "&#039;");
}

function organizationNameVisible() {
  let dataElement = document.querySelector('[data-organization-name-visible]')
  return dataElement ? dataElement.dataset.organizationNameVisible == 'true' : false;
}
