// this function move the elements when their date is updated in the modal
// it places it a new day, takes care to create the new div or week if necessary
// and also remove a day or week if we don't need it anymore
export function updateDomTemplate(values) {
  const data = JSON.parse(values);
  const oldElementData = {
    week: +element().parentElement.parentElement.parentNode.dataset.week || 'day_first-connection',
    day: +element().parentElement.id.split('_')[1]
  }

  // we don't move the element if the date doesn't change
  if (data.new_week == oldElementData.week && data.new_day == oldElementData.day) { return }

  if (data.new_day == null) {
    // append element in first connection div
    document.querySelector('#first-connection .draggable-day').append(element());
  } else {
    if (isMovedInSameWeek()) {
      moveInNewDay();
    } else {
      moveInNewWeek();
    }
	}

  // we can't D&D planned meeting in first connection block
  // so when we tried it reject it, then we don't want to launch to function to update the positions
  const dragMeetingToFirstConnection = (data.model === 'meeting' && oldElementData.week === 'day_first-connection')
  if (!dragMeetingToFirstConnection) {
    updatePositions(data, oldElementData);
  }
  // remove add weeks/days with no elements
  removeDay();
  // make new divs draggable and update add week lines (--- + ---)
  draggableTemplate();

  // the 6 functions below are in updateDomTemplate in order to access to global 'data' variable
  function moveInNewWeek() {
    if (isExistingWeek()) {
      moveInNewDay();
    } else {
      // create and append the new week to DOM
      const htmlWeek = createWeek(data.new_week);
      appendNewWeek(htmlWeek, data.new_week);
      // create and append the new day to the week
      const htmlDay = createDay(data.new_day);
      document.querySelector(`[data-week="${data.new_week}"]`).insertAdjacentHTML('beforeend', htmlDay);
      // append the element to the new day
      appendElementToDay();
      // create cross to remove block
      allowToRemoveTemporalityBlock();
    }
  }

  function moveInNewDay() {
    if (isExistingDay()) {
      // append the element to the day
      appendElementToDay();
    } else {
      // create and append the new day to the week
      const htmlDay = createDay(data.new_day);
      appendNewDay(htmlDay, data.new_day, data.new_week);
      // append the element to the new day
      appendElementToDay();
    }
  }

  function isMovedInSameWeek() {
    return element().parentElement.parentElement.parentElement.dataset.week == data.new_week;
  }

  function element() {
    return document.querySelector(`[data-id="${data.id}"][data-classname="${data.model}"]`);
  }

  function isExistingWeek() {
    return document.querySelector(`[data-week="${data.new_week}"]`);
  }

  function isExistingDay() {
    return document.getElementById(`day_${data.new_day}`);
  }

  function appendElementToDay() {
    return document.getElementById(`day_${data.new_day}`).appendChild(element());
  }
}

function createWeek(week) {
  const translations = JSON.parse(document.querySelector('[data-controller="desktop--settings--templates--editions"]').dataset.translations)
  return `
    <div class="week" style="max-width: 1000px;" data-week="${week}">
      <div class="block-title d-flex justify-content-between">
        <h3 class="padded-20px text-22px text-color-${document.getElementById('setting-content').dataset.mobility}">${translations['week']} ${week}</h3>
      </div>
    </div>
  `;
}

function createDay(day) {
  const mobility = document.getElementById('setting-content').dataset.mobility;
  const translations = JSON.parse(document.querySelector('[data-controller="desktop--settings--templates--editions"]').dataset.translations)
  return `
    <div class="d-flex flex-column align-items-center">
      <span class="day-label border-color-${mobility} text-color-${mobility}">${translations['short_day']} ${day >= 0 ? day + 1 : day}</span>
      <div id="day_${day}"
           class="draggable-day w-100 min-height-30px0"
           style="padding: 0 60px 20px 60px;"
           data-url="${document.querySelector('[data-url]').dataset.url}">
      </div>
    </div>
  `;
}

function appendNewDay(htmlDay, new_day, week) {
  const dayDivs = document.querySelectorAll(`[data-week="${week}"] .draggable-day`);
  const days = [...dayDivs].map(el => +el.id.split('_')[1])
  days.push(new_day);
  const sortedDays = days.sort((a, b) => a > b ? 1 : -1);
  const indexNewDay = sortedDays.indexOf(new_day); // get the place where the new day should be
  // if there a div before we place the new one after it, otherwise it goes first
  if (dayDivs[indexNewDay - 1]) {
    dayDivs[indexNewDay - 1].parentElement.insertAdjacentHTML('afterend', htmlDay);
  } else {
    dayDivs[0].parentElement.insertAdjacentHTML('beforebegin', htmlDay);
  }
}

function updatePositions(new_element, old_element) {
  const fromElements = [...document.querySelector(fromSelector(old_element)).children];
  const toElements = [...document.querySelector(toSelector(new_element)).children];
  let oldDivData = [];
	let newDivData = [];

  if (fromElements.length > 0) {
		setNewPositionsFor(fromElements);
		oldDivData = setObjectToSendFor(fromElements)
	}

	if (toElements.length > 0) {
		setNewPositionsFor(toElements);
		newDivData = setObjectToSendFor(toElements)
	}

  $.ajax({
    url: document.querySelector('[data-url]').dataset.url,
    type: 'PATCH',
    data: { elements: oldDivData.concat(newDivData) },
    dataType: 'script'
  });
}

function fromSelector(element) {
  return element.week == 0 || element.week == 'day_first-connection' ? '#day_first-connection' : `[data-week="${element.week}"] #day_${element.day}`
}

function toSelector(element) {
  return element.new_week == null ? '#day_first-connection' : `[data-week="${element.new_week}"] #day_${element.new_day}`
}

// the 2 functions below update the divs when destroing / updating an element (with the modal or in D&D)
export function removeDay() {
  [...document.querySelectorAll('.draggable-day')].filter(el => isNotToBeRemoved(el))
                                                  .forEach(el => el.parentElement.remove());
  removeWeek();
}

function removeWeek() {
  [...document.querySelectorAll('.week')].filter(el => el.children.length <= 1).forEach(el => el.remove());
}

function isNotToBeRemoved(el) {
  const isEmptyDay = el.children.length == 0;
  const isDayFirstConnection = el.id === 'day_first-connection';
  const isDayMinus7Or0 = el.id.split('_')[1] === '-7' || el.id.split('_')[1] === '0';
  const canBeRemoved = isEmptyDay && !containsUndraggableElements(el) && !isDayFirstConnection && !isDayMinus7Or0
  return canBeRemoved;
}
