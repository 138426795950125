import { tooltip, feedbackTooltip, surveyLinetooltip } from "./tooltip";
import 'chartjs-adapter-date-fns';
import * as dateFnsLocales from 'date-fns/locale';

const blue = 'rgb(46, 91, 236)';
const blueDark = 'rgb(42, 52, 83)';

const surveyColors = ['rgb(61, 46, 236)', 'rgb(46, 111, 236)', 'rgb(46, 190, 236)', 'rgb(46, 236, 225)']

const date = new Date();
const locale = document.querySelector('html').lang
const currentDate = date.toISOString();
const dateFnsLocale = dateFnsLocales[locale];

export const trackLine = {
  onClick: (event) => {
    const points = event.chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

    if (points.length) {
      const firstPoint = points[0];
      const dateAndCount = event.chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
      const value = dateAndCount[1]
      const label = dateAndCount[0]
      const controllerDiv = document.querySelector("div[data-controller*='desktop--hr--charts--sidebar']");

      controllerDiv.setAttribute('data-desktop--hr--charts--sidebar-graph-value' , `
        { "value": "${value}",
          "month": "${label}",
          "url": "/${locale}/actor/analyse/tracks" }
      `);
      document.getElementById('trigger-sidebar').click()
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  backgroundColor: ["rgba(46, 91, 236, 0.30)"],
  pointRadius: 0,
  borderColor: [blue],
  borderWidth: 2,
  fill: "start",
  tension: 0.0,
  segment: {
    borderDash: (ctx) => {
      let today = new Date();
      let pointLastDate = new Date(ctx.p1.raw[0]);
      if (pointLastDate >= today) { return [5, 10] };
    }
  },
  interaction: {
    intersect: false
  },
  plugins: {
    tooltip,
    legend: {
      display: false
    }
  },
  scales: {
    y: {
      display: false,
      grace: '5%',
      grid: {
        drawOnChartArea: false
      },
      ticks: {
        color: blueDark
      },
      beginAtZero: true
    },
    x: {
      adapters: {
        date: {
          locale: dateFnsLocale
        }
      },
      display: true,
      type: 'time',
      time: {
        unit: 'month',
        tooltipFormat: "yyyy-MM-dd'T'HH:mm:ssxxx",
        unitStepSize: 1,
        displayFormats: {
          month: 'MMM yy'
        }
      },
      grid: {
        drawOnChartArea: false
      },
      ticks: {
        color: blueDark
      },
      min: 'auto',
      max: 'auto'
    }
  },
  animation: true
}

// scale survey question
export const surveyLine = {
  onClick: (event) => {
    const points = event.chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

    if (points.length) {
      const firstPoint = points[0];
      const dateAndCount = event.chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
      const value = dateAndCount[1]
      const label = dateAndCount[0]
      const controllerDiv = document.querySelector("div[data-controller*='desktop--hr--charts--sidebar']");

      controllerDiv.setAttribute('data-desktop--hr--charts--sidebar-graph-value' , `
      {
        "survey_type": "survey_line",
        "value": "${value}",
        "month": "${label}",
        "survey_question_resource_id": ${event.chart.canvas.parentElement.dataset.surveyQuestionResourceId},
        "url": "/${locale}/actor/analyse/surveys" }
      `);
      document.getElementById('trigger-sidebar').click()
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  backgroundColor: ["rgba(46, 91, 236, 0.30)"],
  pointRadius: 2,
  borderColor: [blue],
  borderWidth: 2,
  fill: "start",
  tension: 0.3,
  plugins: {
    tooltip: surveyLinetooltip,
    legend: {
      display: false
    },
    title: {
      display: true
    }
  },
  scales: {
    y: {
      display: false,
      grid: {
        drawBorder: false,
        display: false
      },
      ticks: {
        color: blueDark
      },
      beginAtZero: true,
      max: 11
    },
    x: {
      adapters: {
        date: {
          locale: dateFnsLocale
        }
      },
      display: true,
      type: 'time',
      time: {
        unit: 'month',
        tooltipFormat: "yyyy-MM-dd'T'HH:mm:ssxxx",
        unitStepSize: 1,
        displayFormats: {
          month: 'MMM yy '
        }
      },
      grid: {
        display: false,
        drawBorder: false,
        drawOnChartArea: false
      },
      ticks: {
        color: blueDark,
      }
    }
  }
}

export const feedbackVerticalBars = {
  onClick: (event) => {
    const points = event.chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

    if (points.length) {
      const firstPoint = points[0];
      const values = event.chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
      const label = event.chart.data.datasets[firstPoint.datasetIndex].label
      const controller = document.querySelector("div[data-controller*='desktop--hr--charts--sidebar']");
      const email_category = [...document.querySelectorAll('input[name="email_feedback"]')].find(el => el.checked).value

      controller.setAttribute('data-desktop--hr--charts--sidebar-graph-value' , `
        { "value": "${values.y}",
          "month": "${values.x}",
          "content": "${label}",
          "email_category": "${email_category}",
          "url": "/${locale}/actor/analyse/email_feedbacks" }
      `)
      document.getElementById('trigger-sidebar').click()
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    stacked100: { enable: true, replaceTooltipLabel: true, precision: 0 },
    tooltip: feedbackTooltip,
    legend: {
      align: 'start',
      labels: {
        boxWidth: 0,
        boxHeight: 50,
        padding: 0,
        font: {
          size: 20
        },
        generateLabels: ({ data: { legend } }) => {
          if (!legend) { return };

          return legend.map(({ label, number, datasetIndex, fontColor }) => {
            return {
              text: `${label} ${number} `,
              fontColor: fontColor,
              fillStyle: 'transparent',
              strokeStyle: 'transparent',
              datasetIndex: datasetIndex,
            }
          });
        }
      },
      onClick: (_event, legendItem, _legend) => {
        const controller = document.querySelector("div[data-controller*='desktop--hr--charts--sidebar']");
        const value = legendItem.text.split(' ')[0];
        const email_category = [...document.querySelectorAll('input[name="email_feedback"]')].find(el => el.checked).value
        controller.setAttribute('data-desktop--hr--charts--sidebar-graph-value' , `
          { "content": "${value}",
            "email_category": "${email_category}",
            "url": "/${locale}/actor/analyse/email_feedbacks" }
        `)
        document.getElementById('trigger-sidebar').click()
      }
    }
  },
  scales: {
    x: {
      adapters: {
        date: {
          locale: dateFnsLocale
        }
      },
      display: true,
      type: 'time',
      time: {
        unit: 'month',
        tooltipFormat: "yyyy-MM-dd'T'HH:mm:ssxxx",
        unitStepSize: 1,
        displayFormats: {
          month: 'MMM yy'
        }
      },
      stacked: true,
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        color: blueDark,
      }
    },
    y: {
      display: false
    }
  }
}

// multiple or unique choice question of survey
export const surveyVerticalBars = {
  onClick: (event) => {
    const points = event.chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

    if (points.length) {
      const firstPoint = points[0];
      const values = event.chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
      const label = event.chart.data.datasets[firstPoint.datasetIndex].label
      const controller = document.querySelector("div[data-controller*='desktop--hr--charts--sidebar']");

      controller.setAttribute('data-desktop--hr--charts--sidebar-graph-value' , `
        { "survey_type": "survey_bars",
          "value": "${values.y}",
          "month": "${values.x}",
          "content": "${label}",
          "survey_question_resource_id": "${event.chart.canvas.parentElement.dataset.surveyQuestionResourceId}",
          "url": "/${locale}/actor/analyse/surveys" }
      `)
      document.getElementById('trigger-sidebar').click()
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  datasets: {
    bar: (context) => {
      context.chart.data.datasets.forEach((dataset, index) => {
        dataset.backgroundColor = surveyColors[index];
      });
    }
  },
  plugins: {
    tooltip,
    legend:{
      align: 'start',
      onClick: (event, legendItem, _legend) => {
        const controller = document.querySelector("div[data-controller*='desktop--hr--charts--sidebar']");
        const value = legendItem.text;

        controller.setAttribute('data-desktop--hr--charts--sidebar-graph-value' , `
        { "survey_type": "survey_bars",
          "value": "${value}",
          "content": "${value}",
          "survey_question_resource_id": "${event.chart.canvas.parentElement.dataset.surveyQuestionResourceId}",
          "url": "/${locale}/actor/analyse/surveys" }
      `)
        document.getElementById('trigger-sidebar').click()
      }
    }
  },
  scales: {
    x: {
      adapters: {
        date: {
          locale: dateFnsLocale
        }
      },
      display: true,
      type: 'time',
      time: {
        unit: 'month',
        tooltipFormat: "yyyy-MM-dd'T'HH:mm:ssxxx",
        unitStepSize: 1,
        displayFormats: {
          month: 'MMM yy'
        }
      },
      stacked: true,
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        color: blueDark,
      }
    },
    y: {
      display: false,
      stacked: true,
      grid: {
        display: false,
        drawBorder: false
      },
      ticks: {
        color: blueDark
      }
    }
  }
}

// Doughnuts of tracks
export const trackHorizontalBars = {
  onClick: (event) => {
    const points = event.chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

    if (points.length) {
      const firstPoint = points[0];
      const value = event.chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
      const label = event.chart.data.datasets[firstPoint.datasetIndex].label[firstPoint.index];
      const controller = document.querySelector("div[data-controller*='desktop--hr--charts--sidebar']");
      const level_organizations = [...document.querySelectorAll('input[name="level_organization"]')];
      const level_organization_id = level_organizations.length > 1 ? level_organizations.find(el => el.checked).value : document.getElementById('level-organizations-subfilter').dataset.environmentId;
      controller.setAttribute('data-desktop--hr--charts--sidebar-graph-value' , `
        { "value": "${value}",
          "name": "${label}",
          "level_organization_id": "${level_organization_id}",
          "url": "/${locale}/actor/analyse/level_organizations" }
      `)
      document.getElementById('trigger-sidebar').click()
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      color: blueDark,
      font: {
        size: 14,
        weight: 'lighter',
        family: 'Lato'
      }
    },
    tooltip,
    legend: {
      position: 'right',
      labels: {
        color: blueDark,
        font: {
          weight: 'lighter',
          family: 'Lato'
        }
      },
      onClick: (event, legendItem, _legend) => {
        const controller = document.querySelector("div[data-controller*='desktop--hr--charts--sidebar']");
        const value = legendItem.text.split(' ')[0];
        const level_organizations = [...document.querySelectorAll('input[name="level_organization"]')];
        const level_organization_id = level_organizations.length > 1 ? level_organizations.find(el => el.checked).value : document.getElementById('level-organizations-subfilter').dataset.environmentId;
        controller.setAttribute('data-desktop--hr--charts--sidebar-graph-value' , `
          { "value": "${value}",
            "name": "${legendItem.text}",
            "level_organization_id": "${level_organization_id}",
            "url": "/${locale}/actor/analyse/level_organizations" }
        `)
        document.getElementById('trigger-sidebar').click()
      }
    }
  },
  layout: {
    padding: {
      top: -50,
      bottom: -50,
      right: 10,
      left: 10
    }
  }
}

// List of chart types with their corresponding value in Chartjs
// (there are no horizontal-bar in Chartjs)
export const chartTypes = {
  'trackLine': 'line',
  'surveyLine': 'line',
  'trackHorizontalBars': 'doughnut',
  'feedbackVerticalBars': 'bar',
  'surveyVerticalBars': 'bar'
}

export const chartOptions = {
  trackLine,
  surveyLine,
  feedbackVerticalBars,
  trackHorizontalBars,
  surveyVerticalBars,
}
