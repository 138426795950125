import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["formSubmit", "surveySubmit"]

  sendForm() {
    loadContentFor(this.formSubmitTarget, 'PATCH');
  }

  sendSurvey() {
    loadContentFor(this.surveySubmitTarget, 'PATCH');
  }
}

const removeValidationTime = 1500;

function loadContentFor(target, method) {
  addSpinnerScreen();
  let url = target.dataset.url;
  $.ajax({
    url: url,
    type: method,
    dataType: 'json',
    success: (data) => {
      const isValid = data['is_valid'] == true
      if (isValid) {
        renderPage(getActionsList, data)
      } else {
        renderPage(reload, data);
      }
    }
  });
}

function renderPage(request, data = null) {
  displayValidationContent(data);
  setTimeout(() => {
    request(data);
  }, removeValidationTime);
}

function getActionsList(data, callback = null) {
  const locale = document.querySelector('html').lang
  $.ajax({
    url: data['url'],
    type: 'GET',
    dataType: 'json',
    data: { 'origin': 'navigation', 'locale': locale },
    success: (data) => {
      $('#app-content').html(data['content']);
      if (typeof callback == "function") { callback(); }
      if (data['navbar']) {
        $('body').append(data['navbar'])
        navbarAnimation();
      }
      progressBarMobileOnboardee();
    }
  });
}

function reload() {
  window.location.reload();
}

function displayValidationContent(data) {
  if (data) { $('#app-content').html(data['validation']) };
}

function addSpinnerScreen() {
  $('#app-content').html(`
    <div class="bg-blue-ultra-light d-flex justify-content-center align-items-center min-height-100vh-dvh">
      <div class="text-center padded-top-20px">
        <i class="fad fa-spinner text-gray text-80px rotating"></i>
      <div>
    </div>
  `);
}
