export function currentWeeks() {
  return [...document.querySelectorAll('[data-week]')].map(el => el.dataset.week).filter(e => e != '');
}

export function checkIfPrevWeekExists(id) {
	return document.querySelector(`[data-week="${+id - 1}"]`)
}

export function checkIfNextWeekExists(id) {
	return document.querySelector(`[data-week="${+id + 1}"]`)
}

export function createNextWeek(id) {
	if (+id + 1 == 0) { return }; // don't create the week 0

	const div = document.querySelector(`[data-week="${id}"]`)
	if (div.nextElementSibling.classList.contains('add-week')) { return };

	const html = createAddWeekLine(+id + 1);
	div.insertAdjacentHTML('afterend', html);
}

export function createPrevWeek(id) {
  if (+id - 1 == 0 && !checkIfPrevWeekExists(+id - 1)) {
    createAddWeekLine('-1')
  } else if (+id - 1 == 0) {
    return;
  }

	const div = document.querySelector(`[data-week="${id}"]`)
	if (div.previousElementSibling.classList.contains('add-week')) { return };

	const html = createAddWeekLine(+id - 1);
	div.insertAdjacentHTML('beforebegin', html);
}

function createAddWeekLine(id) {
	const templateId = document.getElementById('setting-content').dataset.templateId;
	const language = document.getElementById('setting-content').dataset.language;
	return `
		<div class="add-class position-relative" style="height: 0;">
      <div class="d-flex justify-content-around w-100 position-absolute hover-target" style="top: 35px;">
  			<div class="w-100 margin-bottom-10px visibility-hidden hover-show" style="margin-top: -30px; max-width: 1000px;">
  				<a class="d-flex justify-content-between align-items-center w-100" data-remote="true" href="${'/' + language}/settings/templates/${templateId}/draggable_elements/new">
  				  <div class="width-45 bg-blue border-radius-24px" style="height: 6px;"></div>
  						<i class="fad fa-plus-circle text-40px text-blue pointer ask-week"></i>
  				  <div class="width-45 bg-blue border-radius-24px" style="height: 6px;"></div>
  				</a>
  			</div>
  		</div>
    </div>
	`
}

export function giveIdToPlusButtons() {
	[...document.querySelectorAll('.ask-week')].forEach((plusButton, i) => {
		plusButton.parentElement.parentElement.parentElement.parentElement.id = `add_week_${i + 1}`;
		if (plusButton.parentElement.href.includes('?add_week=')) { return };

		plusButton.parentElement.href += `?add_week=${i + 1}`;
	});
}

export function containsUndraggableElements(draggableDiv) {
	const prevDiv = draggableDiv.previousElementSibling;
	if (prevDiv) {
		return prevDiv.classList.contains('undraggable-day');
	}
}

export function defineNewDay(day) {
	return day == 'first-connection' ? null : +day >= 0 ? +day + 1 : +day;
}

export function defineWeekValue() {
	const div = document.querySelector('.week.dashed-border-blue');
	if (nextDivHasWeek(div)) {
    const num = +div.nextElementSibling.dataset.week - 1;
    return num === 0 ? -1 : num;
	} else if (previousDivHasWeek(div)) {
    const num = +div.previousElementSibling.dataset.week + 1;
    return num + 1 === 0 ? +1 : num
	} else {
		return 1;
	}
}

function nextDivHasWeek(div) {
  const dataset = div.nextElementSibling.dataset;
  return dataset.week ? dataset.week != '' : false;
}

function previousDivHasWeek(div) {
  const dataset = div.previousElementSibling.dataset;
  return dataset.week ? dataset.week != '' : false;
}

export function verifyExistingWeek() {
	const value = document.getElementById('new-week-number-modal').value;
	const submitButton = document.getElementById('new-week-modal-button');
	const warningExistingWeek = document.getElementById('existing-week')

	if (document.querySelector(`[data-week="${value}"]`)) {
		submitButton.disabled = true;
		warningExistingWeek.classList.remove('d-none');
	} else {
		submitButton.disabled = false
		warningExistingWeek.classList.add('d-none');
	}
}

export function weekPlus(selector) {
	const input = document.getElementById(`new-week-number-${selector}`);
	const value = +input.value;
	input.value = value + 1;
	if (input.value == 0) { input.value = 1 }
}

export function weekMinus(selector) {
	const input = document.getElementById(`new-week-number-${selector}`);
	const value = +input.value;
	input.value = value - 1;
	if (input.value == 0) { input.value = -1 }
}

export function appendNewWeek(htmlWeek, new_week) {
  const weeks = currentWeeks();
  weeks.push(new_week);
  const sortedWeeks = weeks.map(e => +e).sort((a, b) => a > b ? 1 : -1);
  const weekDivs = [...document.querySelectorAll('[data-week]')].filter(el => el.dataset.week != '');
  const indexNewDiv = sortedWeeks.indexOf(new_week); // get the place where the new week should be
  // if there a div before we place the new one after it, otherwise it goes first
  if (weekDivs[indexNewDiv - 1]) {
    weekDivs[indexNewDiv - 1].insertAdjacentHTML('afterend', htmlWeek);
  } else {
    weekDivs[0].insertAdjacentHTML('beforebegin', htmlWeek);
  }
}

export function setObjectToSendFor(elements) {
	const data = [];
	elements.forEach(el => {
		// prevent to update undraggable element
		const isElementToUpdate = el.dataset.classname;

		if (isElementToUpdate) {
			const object = {
				id: el.dataset.id,
				model: el.dataset.classname,
				position: el.dataset.position,
			}
			data.push(object);
		}
	});
	return data;
}

export function setNewPositionsFor(elements) {
	elements.forEach((el, index) => { el.dataset.position = index + 1 });
}

export function cleanElementModalContent() {
  const modalContent = document.querySelector('#edit_element .modal-content');
  modalContent.innerHTML = '';
}
