import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  showAction(event) {
    loadPageFor(event.currentTarget);
    $('body').removeClass('bg-color-customed').addClass('bg-blue-ultra-light');
  }
}

function loadPageFor(target) {
  let url = target.dataset.url
  let callback = defineCallback(target);

  $.ajax({
    url: url,
    type: 'GET',
    dataType: 'json',
    data: { 'origin': 'navigation' },
    success: (data) => {
      $('navbar').remove();
      $('#app-content').html(data['content']);
      if (typeof callback == "function") { callback(); }
    }
  });
}

function defineCallback(target) {
  let callbackString = target.dataset.callback
  if (callbackString) { return window[callbackString] }
}
