import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use';

export default class extends Controller {
  static values = { conditionalLogicId: String,
                    id: String,
                    field: String,
                    operator: String,
                    value: String,
                    collection: Array,
                    wording: Object }
  static targets = ['field', 'operator', 'valueSelect', 'valueInput', 'dropdownTrigger', 'conditionRelation']

  enableStoring = false;
  conditionalLogic = this.element.closest('.conditional-logic');
  relationTypes = JSON.parse(document.getElementById('logic-condition-relation-types').value);

  connect() {
    useClickOutside(this);
    this.initMouseHover();
    this.initializeField();
    this.initializeOperator();
    this.initializeConditionRelation();
    this.enableStoring = true;
    this.element.dataset['readyToStore'] = 'true';
  }

  disconnect() {
    this.unmarkFieldAsConditioned();
  }

  remove() {
    this.element.dataset['readyToStore'] = 'true';
    this.element.remove();
  }

  initializeField() {
    let element = this.fieldTarget;
    let options = this.withoutFileOrDateFields();
    let callback = this.setConditionField.bind(this);

    initializeSelectize(element, options, callback, this.fieldValue);
  }

  initializeOperator() {
    let element = this.operatorTarget;

    if (this.operatorValue == 'is_included') {
      element.classList.add('d-none');
      this.valueInputTarget.setAttribute('hidden', '');

      this.operatorTarget.outerHTML = `
        <div class='padded-lateral-5px'>
          <div class='cursor-disabled selectize-input padded-lateral-10px'>
            ${this.wordingValue.is_included}
          </div>
        </div>
      `
      this.valueSelectTarget.outerHTML = `
        <div class='padded-lateral-5px'>
          <div class="padded-lateral-10px pointer selectize-input"
              data-toggle="modal" data-target="#${this.element.id}_list">
            <i class="fad fa-list padded-right-5px"></i> ${this.wordingValue.this_list}
          </div>
        </div>
      `

      let list = "<ul class='no-margin'>";
      this.collectionValue.forEach(collectionValue => {
        list += `<li>${collectionValue}</li>`
      })
      list += "</ul>"
      document.getElementById('page-content').insertAdjacentHTML('beforeend', `
        <div id="${this.element.id}_list" class="modal fade" tabindex="1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-xs" role="document">
            <div class="modal-content padded-small">
              <a href="" data-toggle="modal" data-target="#${this.element.id}_list" class="position-absolute position-top-right-10px link-decoration-none">
                <i name="category" class="fa fa-times-circle float-right close-menu text-gray-less-dark" aria-hidden="true"></i>
              </a>
              ${list}
            </div>
          </div>
        <div>
      `)
    } else {
      let options = this.operators();
      let callback = (value) => {
        this.operatorValue = value;
        this.initializeField();
      }

      initializeSelectize(element, options, callback, this.operatorValue);
    }
  }

  initializeConditionRelation() {
    let relationValue = this.conditionalLogic.dataset.conditionalLogicsConditionRelationValue;
    let callback = (value) => this.conditionalLogic.dataset.conditionalLogicsConditionRelationValue = value;
    initializeSelectize(this.conditionRelationTarget, this.relationTypes, callback, relationValue);
  }

  initializeConditionValueSelector(options) {
    if (this.hasValueSelectTarget) {
      let element = this.valueSelectTarget;
      if (element) {
        let callback = (value) => {
          this.valueValue = value;
          this.storeConditionLogic();
        }
        initializeSelectize(element, options, callback, this.valueValue);
      }
    }
  }

  setConditionField(fieldId) {
    document.getElementById(fieldId).dataset.conditionalLogic = 'true';
    this.valueInputTarget.value =  this.valueValue;
    let oldFieldId = this.fieldValue;
    this.fieldValue = fieldId;
    this.initConditionFieldFor(fieldId);
    this.unmarkFieldAsConditioned(oldFieldId);
  }

  hideConditionValue() {
    this.hideConditionValueSelect();
    this.hideConditionValueInput();
    this.valueValue = '';
    this.storeConditionLogic();
  }

  hideConditionValueSelect() {
    let selectize = this.valueSelectTarget.selectize;
    if (selectize) { selectize.clear(); selectize.destroy() };
    this.valueSelectTarget.style.display = 'none';
  }

  displayConditionValueSelect(collections) {
    if (this.hasValueSelectTarget) { this.valueSelectTarget.selectize?.destroy() }; // remove old selectize
    collections = collections.map(el => { return { name: el, value: el } });
    this.initializeConditionValueSelector(collections);
    if (this.hasValueSelectTarget) {
      this.valueSelectTarget.parentNode.querySelector('.selectize-control').classList.remove('d-none');
    };
  }

  hideConditionValueInput() {
    this.valueInputTarget.value = '';
    this.valueInputTarget.classList.add('d-none');
  }

  displayConditionValueInput() {
    if (this.valueInputTarget.classList.contains('d-none')) { this.valueInputTarget.value = ''}
    this.valueInputTarget.classList.remove('d-none');
    this.storeConditionLogic();
  }

  withoutFileOrDateFields() {
    const paperworks = fields();
    const withoutFilePaperworks = paperworks.filter(el => !["no_model_file", "generic_file", "specific_file", "date"].includes(el.new_data));
    return withoutFilePaperworks;
  }

  updateConditionValue() {
    let value = this.valueInputTarget.value;
    this.valueValue = value;
    this.storeConditionLogic();
  }

  storeConditionLogic() {
    if (this.enableStoring) { this.element.dataset['readyToStore'] = 'true' }
  }

  toJSON() {
    return {
      id: this.idValue || newId(),
      operator_type: this.hasOperatorTarget ? this.operatorTarget.value : 'is_included',
      field_id: this.fieldTarget.value,
      value: this.valueValue == 'null' ? '' : this.valueValue,
      collection: JSON.stringify(this.hasCollectionValue ? this.collectionValue : new Array())
    }
  }

  operators() {
    return JSON.parse(document.getElementById('logic-condition-operator-types').value);
  }

  initConditionFieldFor(fieldId) {
    const field = document.querySelector(`[data-type="paperwork"][id="${fieldId}"]`);
    const paperworkResourceId = field.dataset.paperworkResourceId;
    const url = `/actor/account/paperwork_resources/${paperworkResourceId}/collection_values.json`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        let collections = data;
        if (collections && (["is_empty", "is_not_empty"].includes(this.operatorValue))) {
          this.hideConditionValue();

        } else if (collections && (collections.length === 0)) {
          this.displayConditionValueInput();
          this.hideConditionValueSelect();

        } else if (collections) {
          this.displayConditionValueSelect(collections);
          this.hideConditionValueInput();
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  unmarkFieldAsConditioned(fieldId = this.fieldValue) {
    let field = document.getElementById(fieldId)
    if (fieldId && field && !document.querySelector(`[data-logic-conditions-field-value="${fieldId}"]`)) {
      field.dataset.conditionalLogic = 'false';
    }
  }

  initMouseHover() {
    this.element.addEventListener('mouseover', () => { this.dropdownTriggerTarget.classList.remove('invisible')});
    this.element.addEventListener('mouseout', () => { this.dropdownTriggerTarget.classList.add('invisible')});
  }

  stickDropdown() {
    this.element.setAttribute('dropdown-focus', '');
  }

  clickOutside() {
    this.hideDropdown();
    this.element.removeAttribute('dropdown-focus');
  }

  hideDropdown() {
    $(this.element.querySelector('[data-toggle=dropdown]')).dropdown('hide');
  }
}

export const initializeSelectize = (element, options, callback = null, initialValue = null) => {
  let selectize = $(element);
  selectize.selectize({
    options: options,
    labelField: 'name',
    searchField: ['name'],
    onChange: callback
  });
  selectize[0].selectize.setValue(initialValue || options[0].value);
}

export const fields = () => {
  const paperworks = [];
  document.querySelectorAll('[data-type="paperwork"]').forEach((paperwork) => {
    let data = {
      "new_data": paperwork.dataset.newData,
      "value": paperwork.id,
      "name": paperwork.querySelector('[data-paperwork-resources-target="input"]').innerText
    };
    paperworks.push(data);
  });
  return paperworks;
}
