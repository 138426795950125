import DelayerController from "../filters/delayer_controller"

export default class extends DelayerController  {
  // Executed callback when a mutation is observed
  delayRequest(_mutationsList) {
    const target = event.currentTarget
    if (isClickedInDropdowns(target) && !isClickedOnSelectOrRemoveAll(target)) {
      delayChartRequest(1000)
    }
  }
}

// Need this variable to store and clear previous timeout
let tracksTimer;
// Clear previous tracksTimer and launch request
const delayChartRequest = delay => {
  const controller = Stimulus.controllers.find(controller => controller.element.dataset.controller.includes('desktop--hr--chart-filters'))
  window.clearTimeout(tracksTimer);
  tracksTimer = window.setTimeout(() => { controller.refreshCharts() }, delay)
}

const isClickedInDropdowns = (target) => {
  const mobilityMenu = document.getElementById('mobilities-menu');
  const organizationsMenu = document.getElementById('organizations-menu');
  const templatesMenu = document.getElementById('templates-menu');
  return (mobilityMenu && mobilityMenu.contains(target)) ||
           (organizationsMenu && organizationsMenu.contains(target)) ||
           (templatesMenu && templatesMenu.contains(target))
};

const isClickedOnSelectOrRemoveAll = target => {
  return target.getAttribute('data-action').includes('#selectAll') || target.getAttribute('data-action').includes('#removeAll')
};
