import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    id: String,
    resourceId: String,
    formResourceId: String,
    conditionedResourceId: String,
    submitOnBlur: Boolean
  }

  connect() {
    $(this.element).selectize(this.config());
  }

  config() {
    return {
      create: false,
      preload: true,
      valueField: 'value',
      labelField: 'value',
      searchField: ['value', 'external_id'],
      render: {
        option: (item, escape) => {
          let label = '<span>' + escape(item.value) + '</span>';
          if (typeof item.external_id !== 'undefined' && item.external_id !== null && item.external_id != '') {
            label += '<span class="text-overflow text-gray-medium padded-left-10px">' + escape(item.external_id) + '</span>';
          }
          return '<div class="w-100 d-flex justify-content-between" style="padding: 6px 15px;">' + label + '</div>';
        }
      },
      onFocus: () => {
        if (this.conditionedResourceIdValue) {
          $(this.element).selectize()[0].selectize.clearOptions();
          this.loadOptions('', (response) => {
            $(this.element).selectize()[0].selectize.addOption(response);
          });
        };
      },
      load: (query, callback) => {
        this.loadOptions(query, callback);
      },
      onChange: () => {
        document.querySelectorAll(
          `[data-utils--selectizes-paperworks-conditioned-resource-id-value="${this.resourceIdValue}"]`
        ).forEach((element) => {
          $(element).selectize()[0].selectize.setValue('');
        });
        if (!this.submitOnBlurValue) { this.submit() }
      },
      onBlur: () => {
        if (this.submitOnBlurValue) { this.submit() }
      }
    }
  }

  loadOptions(query, callback) {
    $.ajax({
      url: this.urlValue,
      type: 'GET',
      dataType: 'json',
      data: {
        query: query,
        id: this.idValue,
        resource_id: this.resourceIdValue,
        form_resource_id: this.formResourceIdValue,
        conditioned_resource_id: this.conditionedResourceIdValue,
        conditioned_resource_value: this.conditionedResourceValue()
      },
      error: () => {
        callback();
      },
      success: (response) => {
        callback(response);
      }
    });
  }

  conditionedResourceValue() {
    let conditionedResource = document.querySelector(
      `#paperwork_resource_${this.conditionedResourceIdValue} select`
    )
    if (conditionedResource) {
      return conditionedResource.value;
    }
  }

  submit() {
    let event = new Event('change');
    this.element.dispatchEvent(event);
    setTimeout(() => { $(this.element.form).submit(); }, 100)
  }
}
