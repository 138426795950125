import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "dashboardLink", "myBookLink", "myTracksLink",
    "myContactLink", "myCalendarLink", "myCollaboratorLink", "askLink"
  ]

  loadDashboardWithProgress() {
    if (document.getElementById('action-block')) { return }
    updatePageFor(this.dashboardLinkTarget, progressBarMobileOnboardee);
    // PWA Apple Status bar
    $('body').removeClass('bg-blue-ultra-light').addClass('bg-color-customed');
  }

  loadDashboard() {
    if (document.getElementById('action-block')) { return }
    updatePageFor(this.dashboardLinkTarget);
    // PWA Apple Status bar
    $('body').removeClass('bg-blue-ultra-light').addClass('bg-color-customed');
  }

  loadMyBook() {
    if (document.getElementById('pages-index')) { return }
    updatePageFor(this.myBookLinkTarget);
    // PWA Apple Status bar
    $('body').removeClass('bg-color-customed').addClass('bg-blue-ultra-light');
  }

  loadMyCalendar() {
    if (document.getElementById('fullcalendarMobileOnboardee')) { return }
    updatePageFor(this.myCalendarLinkTarget, initializeCalendarMobileOnboardee);
    // PWA Apple Status bar
    $('body').removeClass('bg-color-customed').addClass('bg-blue-ultra-light');
  }

  loadMyCollaborators() {
    if (document.getElementById('collaborators')) { return }
    updatePageFor(this.myCollaboratorLinkTarget);
    // PWA Apple Status bar
    $('body').removeClass('bg-color-customed').addClass('bg-blue-ultra-light');
  }

  loadMyContacts() {
    if (document.getElementById('contacts')) { return }
    updatePageFor(this.myContactLinkTarget);
    // PWA Apple Status bar
    $('body').removeClass('bg-color-customed').addClass('bg-blue-ultra-light');
  }

  loadAsk() {
    if (document.getElementById('ask')) { return }
    updatePageFor(this.askLinkTarget);
    // PWA Apple Status bar
    $('body').removeClass('bg-color-customed').addClass('bg-blue-ultra-light');
  }
}



function updatePageFor(target, callback = null) {
  updateIcons(target);
  addWaitingScreen();
  loadContentFor(target, callback);
}

function updateIcons(target) {
  document.querySelectorAll('navbar [data-mobile--navbar-links-target]').forEach(el => el.removeAttribute('active'));
  target.setAttribute('active', '');
}

function addWaitingScreen() {
  $('#app-content').html(`
    <div class="bg-blue-ultra-light d-flex justify-content-center align-items-center" style="min-height: calc(100vh - 70px);">
      <div class="text-center padded-top-20px">
        <i class="fad fa-spinner text-gray text-80px rotating"></i>
      <div>
    </div>
  `);
}

function loadContentFor(target, callback) {
  let url = target.dataset.url
  $.ajax({
    url: url,
    type: 'GET',
    dataType: 'json',
    data: { 'origin': 'navigation' },
    success: (data) => {
      $('#app-content').html(data['content']);
      if (typeof callback == "function") { callback(); }
    }
  });
}
